<template>
  <div class="flex flex-1">
    <template v-if="canPracticeBrick">
      <Button
        type="link"
        size="small"
        class="flex flex-1"
        :class="backgroundClass"
        style="padding: 0 0 !important; font-size: inherit !important"
        @click="showPracticeDialog = true"
      >
        <div
          :class="contentClass"
          class="rounded h-full"
          style="border-radius: 0.65rem"
        >
          <div class="bareka-brick-overlay">
            {{ brick.name }}
            <slot />
          </div>
        </div>
      </Button>
      <PracticeDialog
        v-if="showPracticeDialog"
        :brick="brick"
        :message="confirmationMessage"
        @ok="$emit('start-practice', brick.id)"
        @cancel="showPracticeDialog = false"
      />
    </template>
    <template v-else>
      <div :class="contentClass" @click="showPracticeUnavailableDialog = true">
        <div class="bareka-brick-overlay">
          {{ brick.name }}
          <slot />
        </div>
      </div>
      <PracticeUnavailableDialog
        v-if="showPracticeUnavailableDialog"
        :message="practiceUnavailableMessage"
        :show-speed-brick="showSpeedBrickExampleForUnavailable"
        @close="showPracticeUnavailableDialog = false"
      />
    </template>
  </div>
</template>

<script>
import Button from '../../button/Button.vue'
import PracticeDialog from './PracticeDialog.vue'
import PracticeUnavailableDialog from './PracticeUnavailableDialog.vue'

export default {
  components: { PracticeUnavailableDialog, PracticeDialog, Button },
  props: {
    brick: { type: Object, required: true },
    userPrograms: { type: Array, default: null },
    getContentClass: { type: Function, required: true },
    getBackgroundClass: { type: Function, required: true },
    practiceMessages: { type: Object, default: () => ({}) },
    showPracticeUnavailableMessageSpeedBrick: { type: Boolean, default: false }
  },
  data() {
    return {
      showPracticeDialog: false,
      showPracticeUnavailableDialog: false
    }
  },
  computed: {
    canPracticeBrick() {
      if (this.brick.userResults[0].practiceUnavailableReason === 'hasProgram')
        return true
      return this.brick.userResults[0].canPracticeBrick
    },
    backgroundClass() {
      return this.getBackgroundClass(this.brick)
    },
    contentClass() {
      return this.getContentClass(this.brick)
    },
    practiceUnavailableReason() {
      return this.brick.userResults[0].practiceUnavailableReason
    },
    isBrickPlanned() {
      if (this.userPrograms) {
        //find if brick is planned at rekensprint
        const userProgramIndex = this.userPrograms.findIndex(
          (program) => program.brick?.id === this.brick.id
        )

        return userProgramIndex > -1
      }
      if (this.brick.userResults[0].practiceUnavailableReason === 'hasProgram')
        return true
        
      return false
    },
    confirmationMessage() {
      return this.isBrickPlanned
        ? 'Wil je verder oefenen?'
        : this.practiceMessages.confirmation ||
            `Wil je extra op steentje ${this.brick.name} oefenen?`
    },
    practiceUnavailableMessage() {
      return (
        this.practiceMessages?.unavailableMessages[
          this.practiceUnavailableReason
        ] ?? 'Je kan op dit steentje niet oefenen.'
      ) // TODO need a better way to handle unknown reason
      // en: you can not practice on this brick.
    },
    showSpeedBrickExampleForUnavailable() {
      return (
        this.practiceUnavailableReason === 'noPracticeBrick' &&
        this.showPracticeUnavailableMessageSpeedBrick
      )
    }
  }
}
</script>
