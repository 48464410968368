<template>
  <Dialog :buttons="[{ title: 'Ok', handler: () => $emit('close') }]">
    <template #content>
      <p>{{ message }}</p>
      <!-- needs bareka-brick--speed and inside an bareka-brick--overlay to work, style from WallPhase.vue -->
      <div v-if="showSpeedBrick" class="bareka-brick--speed">
        <div
          class="speedBrickExample bg-score-green bareka-brick-overlay bareka-brick-content"
        />
      </div>
    </template>
  </Dialog>
</template>

<script>
import { Dialog } from 'component-library'

export default {
  components: { Dialog },
  props: {
    message: { type: String, required: true },
    showSpeedBrick: { type: Boolean, default: false }
  }
}
</script>

<style>
.speedBrickExample {
  width: 10rem;
  height: 3rem;
  position: relative;
  margin: auto;
  margin-top: 1rem;
}
</style>
